<script>
import { required } from "vuelidate/lib/validators";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
// import tableData from "@/components/tablecomponent";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
// import { _ } from "vue-underscore";

// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "เพิ่มข้อมูลจ่ายอะไหล่",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    // tableData,
    Multiselect,
    DatePicker,
  },
  // Multiselect, DatePicker,
  data() {
    return {
      // tableData: tableData,
      loading: false,
      isLoading: false,
      errormessage: "กรุณากรอกข้อมูลให้ครบ",

      title: "เพิ่มข้อมูลจ่ายอะไหล่ (หรือแก้ไข)",
      items: [
        {
          text: "อะไหล่",
          active: true,
        },
        {
          text: "จ่ายอะไหล่",
          active: false,
          href: "/parts/part-picking",
        },
        {
          text: "เพิ่มข้อมูลจ่ายอะไหล่ (หรือแก้ไข)",
          active: true,
        },
      ],
      userBranch: "",
      selected: [],
      optionBranch: [],
      submitform: false,
      rows: [],
      userBranchId: "",
      pickingDate: [{ reqDate: "" }],
      dcPer: "",
      defaultDcPer: 0,
      defaultDcPrice: 0,
      vatPer: appConfig.vat,
      pickingId: this.$route.params.pickingId,
      rowsPart: [],
      // partItem: [],
      isHidden: true,
      partsCode: [],
      nameState: null,
      amount: 1,
      currentPage: 1,
      rowParts: [],
      pkVatPrice: "",
      pkVatPer: 7,
      pkTotalPrice: "",
      pkNetPrice: 0,
      pkGrandTotalPrice: "",
      pkDcPer: 0,
      pkDcPrice: "",
      totalpkgrandTotalPricepost: "",
      totalPkDcPrice: 0,
      totalnetprice: 0,
      rowsVehicle: [],
      isShow: false,
      btnShow: true,
      btnSaveShow: false,

      // reqAmount: 0,
      // reqAccruedAmount: 0,
      part: {
        dcPer: 0,
        partCode: "",
        partNameTh: "",
        pricePerOne: 0,
        amount: 1,
        totalPrice: 0,
        // dcPer:0,
        dcPrice: 0,
        netPrice: 0,
        vatPer: 0,
        vatPrice: 0,
        grandTotalPrice: 0,
        partId: "",
        nameTh: "",
        sellPrice: 0,
      },
      pricePerOne: "",
      totalPrice: "",
      grandTotalPrice: "",
      totalpkDcPer: "",
      totalpkVatPer: 0,
      totalpkVatPrice: 0,
      totalpkgrandTotalPrice: 0,
      pickingVatPrice: 0,

      note: "",
      customerNameTh: "",
      customerFamilyNameTh: "",
      filter: {
        roCode: "",
        customerNameTh: "",
        customerFamilyNameTh: "",
        licensePlate: "",
        reqDate: "",
      },
      filterOn: [],
      totalRecord: "",
      perPage: 10,
      selectMode: "single",
      sortBy: "age",
      sortDesc: false,
      rowsStock: [],
      pageOptions: [5, 10, 25, 50, 100],
      rowsUser: [],
      rowsRequis: [],
      reqCode: "",
      licensePlate: "",
      reqId: "",
      cusId: "",
      stockId: "",
      pkStatus: "",
      // delHide: false,
      fields: [
        {
          key: "index",
          label: "ลำดับ",
        },
        {
          key: "reqCode",
          sortable: true,
          label: "reqCode",
        },
        {
          key: "customerNameTh",
          sortable: true,
          label: "ชื่อ",
        },
        {
          key: "customerFamilyNameTh",
          sortable: true,
          label: "นามสกุล",
        },
        {
          key: "licensePlate",
          sortable: true,
          label: "ป้ายทะเบียน",
        },
        {
          key: "grandTotalPrice",
          sortable: true,
          label: "ยอดรวม",
        },
        {
          key: "reqDate",
          sortable: true,
          label: "วันที่",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
      ],
    };
  },
  validations: {
    pickingDate: {
      reqDate: {
        required,
      },
    },
    dcPer: {
      required,
    },
    vatPer: {
      required,
    },
  },
  computed: {},
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.userBranch = arrayBranch;
    this.optionBranch = user.userAccessBranch;
    this.userBranchId = user.branchId;
    this.getDataCustomer();
    this.getDataVehicle();
    this.getDataStock();
    if (this.pickingId != null) {
      this.getData();
      this.getDataShowPart();
      this.isShow = true;
      this.btnShow = false;
      this.btnSaveShow = true;
    }
  },
  created() {
    // this.getData();
    //
    // this.getParts();
  },
  methods: {
    getData: function() {
      useNetw
        .get("api/part-picking/show", {
          params: {
            pickingId: this.pickingId,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.pkVatPrice = response.data.data.vatPrice;
          this.pkVatPer = response.data.data.vatPer;
          this.pkTotalPrice = response.data.data.totalPrice;
          this.pkNetPrice = response.data.data.netPrice;
          this.pkGrandTotalPrice = response.data.data.grandTotalPrice;
          this.pkDcPer = response.data.data.dcPer;
          this.pkDcPrice = response.data.data.dcPrice;
          this.pkStatus = response.data.data.status;
          this.branchShow = response.data.data.branchId;

          if (response.data.data.customerNameTh != null) {
            this.customerNameTh = {
              cusId: response.data.data.cusId,
              nameTh: response.data.data.customerNameTh,
              familyNameTh: response.data.data.customerFamilyNameTh,
            };
          }
          this.licensePlate = {
            vehicleId: response.data.data.vehicleId,
            licensePlate: response.data.data.licensePlate,
          };
          if (response.data.data.stockId != null) {
            this.stockId = {
              stockId: response.data.data.stockId,
            };
          }
          if (response.data.data.reqCode != null) {
            this.reqCode = {
              reqId: response.data.data.reqId,
              reqCode: response.data.data.reqCode,
            };
          }
          if (response.data.data.note != null) {
            this.note = response.data.data.note;
          }
          // if (response.data.data.stockId != null) {
          //   if (response.data.data.stockId == this.stockId.stockId) {
          //     this.stockId = { stockId : response.data.data.stockId, nameTh: this.stockId.nameTh }
          //   }
          // }
          this.pickingDate.reqDate = response.data.data.pickingDate;
          this.dcPer = response.data.data.dcPer;
          // this.calParts(this.rowParts);
          //   this.rows.to = response.data.to;
          //   this.rows.from = response.data.from;
          //   this.rows.total = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    getDataShowPart: function() {
      useNetw
        .get("api/part-picking/parts", {
          params: {
            pickingId: this.pickingId,
          },
        })
        .then((response) => {
          this.rowsPart = response.data.data;
          // this.rowsPart.forEach((item) => {
          //   this.reqAccruedAmount = item.reqAccruedAmount;
          //   this.reqAmount = item.reqAmount;
          // });

          this.calParts(this.rowsPart);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    getParts: function(text) {
      useNetw
        .get("api/part", {
          params: {
            page: this.currentPage,
            perPage: 100, //เฉพาะค้นหา สูงสุด 100
            "branchId[]": this.branchShow,
            nameTh: text,
          },
        })
        .then((response) => {
          this.rowParts = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
        });
    },
    showModal(partsCode) {
      // this.part = partsCode;
      this.part.partId = partsCode.partId;
      this.part.partCode = partsCode.partCode;
      this.part.nameTh = partsCode.nameTh;
      this.part.pricePerOne = partsCode.sellPrice;
      this.part.totalPrice = partsCode.sellPrice;
      this.part.netPrice = partsCode.sellPrice;
      this.part.nameTh = partsCode.nameTh;

      this.$refs.modalAmout.show(partsCode);
    },
    calParts(part) {
      part.totalPrice = parseFloat(part.pricePerOne) * parseFloat(part.amount);
      part.dcPrice =
        ((parseFloat(part.totalPrice) * parseFloat(part.amount)) / 100) *
        parseFloat(part.dcPer);
      part.netPrice = parseFloat(part.totalPrice) - parseFloat(part.dcPrice);
      part.vatPrice = (parseFloat(part.netPrice) / 100) * 7;
      part.grandTotalPrice =
        parseFloat(part.netPrice) + parseFloat(part.vatPrice);
      let calPickingVatPrice;
      calPickingVatPrice =
        (parseFloat(this.pkNetPrice) / 100) * parseFloat(this.pkVatPer);
      if (!isNaN(calPickingVatPrice)) {
        //ไม่ให้เป็น NaN
        this.reqDcPrice = calPickingVatPrice;
      }
      this.pickingVatPrice = parseFloat(calPickingVatPrice);
      let sum = 0;
      let picvatper = 0;
      let pkdcper = 0;
      let pkdcprice = 0;
      // let pksaleprice = 0;
      // let pkvatprice = 0;
      let netpricesum = 0;
      // let pkgrandTotalPrice = 0;
      // let pkgrandTotalPricepost = 0;
      this.rowsPart.forEach(function(item) {
        sum += parseFloat(item.pricePerOne) * parseFloat(item.amount);
        netpricesum += parseFloat(item.netPrice);
        picvatper += parseFloat(item.vatPer);
        pkdcper += parseFloat(item.dcPer);
        pkdcprice += parseFloat(item.dcPrice);
        // pksaleprice += parseFloat(item.pricePerOne);
        // pkvatprice += parseFloat(item.vatPrice);
        // pkgrandTotalPrice += parseFloat(item.grandTotalPrice);
        // pkgrandTotalPricepost += parseFloat(item.grandTotalPrice)
      });
      this.totalnetprice = parseFloat(netpricesum);
      this.pkTotalPrice = parseFloat(sum);
      this.totalpkVatPer = parseFloat(picvatper);
      this.totalpkDcPer = parseFloat(pkdcper);
      this.pkDcPrice = parseFloat(pkdcprice);
      this.totalPkDcPrice =
        (parseFloat(this.totalnetprice) / 100) * parseFloat(this.pkDcPer);
      this.pkNetPrice =
        parseFloat(this.totalnetprice) - parseFloat(this.totalPkDcPrice);
      this.totalpkVatPrice =
        (parseFloat(this.pkNetPrice) / 100) * parseFloat(this.pkVatPer);
      this.totalpkgrandTotalPrice =
        parseFloat(this.totalpkVatPrice) + parseFloat(this.pkNetPrice);
    },
    postPkPart: function(part) {
      // var uniqe = _.findWhere(this.rowsPart, part.partId);
      // if (uniqe == null) {
      const arrPart = [];
      this.rowsPart.forEach((item) => {
        arrPart.push(item.partId);
      });
      if (!arrPart.includes(part.partId)) {
        this.rowsPart.push(part);
      }

      // }
      // this.rowsPart.push(part);
      this.calParts(part);
      // this.calParts(this.rowsPart)
      // this.part.pricePerOne = part.sellPrice;
      // this.part.totalPrice = part.sellPrice * this.part.amount;
      // this.pickingVatPrice = (this.part.totalPrice / 100) * this.pkVatPer;
      // this.grandTotalPrice = this.part.totalPrice + this.pickingVatPrice;
      this.overlayFlag = true;
      const formDataPart = new FormData();
      formDataPart.append("pickingId", this.pickingId);
      formDataPart.append("partId", part.partId);
      formDataPart.append("amount", this.part.amount);
      formDataPart.append("pricePerOne", this.part.pricePerOne);
      formDataPart.append("totalPrice", this.part.totalPrice);
      formDataPart.append("dcPrice", this.defaultDcPrice);
      formDataPart.append("dcPer", this.defaultDcPer);
      formDataPart.append("netPrice", this.part.netPrice);
      formDataPart.append("vatPer", this.part.vatPer);
      formDataPart.append("vatPrice", this.part.vatPrice);
      formDataPart.append("grandTotalPrice", this.part.grandTotalPrice);
      formDataPart.append(
        "pickingTotalPrice",
        this.totalnetprice === 0 ? this.totalnetprice : this.totalnetprice
      );
      formDataPart.append(
        "pickingDcPer",
        this.pkDcPer === 0 ? this.pkDcPer : this.pkDcPer
      );
      formDataPart.append(
        "pickingDcPrice",
        this.totalPkDcPrice === 0 ? this.totalPkDcPrice : this.totalPkDcPrice
      );
      formDataPart.append(
        "pickingNetPrice",
        this.pkNetPrice === 0 ? this.pkNetPrice : this.pkNetPrice
      );
      formDataPart.append(
        "pickingVatPer",
        this.pkVatPer === 0 ? this.pkVatPer : this.pkVatPer
      );
      formDataPart.append(
        "pickingVatPrice",
        this.totalpkVatPrice === 0 ? this.totalpkVatPrice : this.totalpkVatPrice
      );
      formDataPart.append(
        "pickingGrandTotalPrice",
        this.totalpkgrandTotalPrice
      );
      // if (this.rowsPart.length === 0) {
      //   formDataPart.append(
      //     "pickingGrandTotalPrice",
      //     (this.totalpkgrandTotalPrice =
      //       part.sellPrice * this.amount +
      //       ((part.sellPrice * this.amount) / 100) * this.vatPer -
      //       this.pkDcPrice)
      //   );
      // } else {
      //   formDataPart.append(
      //     "pickingGrandTotalPrice",
      //     this.totalpkgrandTotalPrice
      //   );
      // }

      useNetw
        .post("api/part-picking/part/store", formDataPart)
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$refs["modalAmout"].hide();
          this.getDataShowPart();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          this.calParts(this.rowsPart);
          // this.$router.push({ name: "branch-wages" });
        });
    },
    updatePkPart: function(part) {
      this.calParts(part);
      this.pricePerOne = parseFloat(part.sellPrice).toFixed(2);
      this.totalPrice = part.sellPrice * this.amount;
      this.pickingVatPrice = (this.totalPrice / 100) * this.pickingVatPrice;
      this.grandTotalPrice = this.totalPrice + this.pickingVatPrice;
      this.overlayFlag = true;
      useNetw
        .put("api/part-picking/part/update", {
          pickingId: this.pickingId,
          partId: part.partId,
          amount: part.amount,
          pricePerOne: part.pricePerOne,
          totalPrice: part.totalPrice,
          dcPrice: part.dcPrice,
          dcPer: part.dcPer,
          netPrice: part.netPrice,
          vatPer: this.pkVatPer,
          vatPrice: part.vatPrice,
          grandTotalPrice: part.grandTotalPrice,
          // reqAmount: this.reqAmount === 0 ? this.reqAmount : this.reqAmount,
          // reqAccruedAmount:
          //   this.reqAccruedAmount === 0
          //     ? this.reqAccruedAmount
          //     : this.reqAccruedAmount,
          pickingTotalPrice:
            this.totalnetprice === 0 ? this.totalnetprice : this.totalnetprice,
          pickingDcPer: this.pkDcPer === 0 ? this.pkDcPer : this.pkDcPer,
          pickingDcPrice:
            this.totalPkDcPrice === 0
              ? this.totalPkDcPrice
              : this.totalPkDcPrice,
          pickingNetPrice:
            this.pkNetPrice === 0 ? this.pkNetPrice : this.pkNetPrice,
          pickingVatPer: this.pkVatPer === 0 ? this.pkVatPer : this.pkVatPer,
          pickingVatPrice: this.totalpkVatPrice,
          pickingGrandTotalPrice: this.totalpkgrandTotalPrice,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$refs["modalAmout"].hide();
          // this.getDataShowPart();
          //   this.getPoPart(this.poId);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          // this.$router.push({ name: "branch-wages" });
        });
    },
    updatePkPartMaster: function() {
      this.overlayFlag = true;
      useNetw
        .put("api/part-picking/update", {
          pickingId: this.pickingId,

          reqId: this.reqCode.reqId != null ? this.reqCode.reqId: undefined,
          cusId:
            this.customerNameTh.cusId != null
              ? this.customerNameTh.cusId
              : undefined,
          stockId:
            this.stockId.stockId != null ? this.stockId.stockId : undefined,
          vehicleId:
            this.licensePlate.vehicleId != null
              ? this.licensePlate.vehicleId
              : undefined,

          pickingDate: this.pickingDate.reqDate,
          totalPrice: this.pkTotalPrice,
          dcPer: this.dcPer,
          dcPrice: this.totalPkDcPrice,
          netPrice: this.pkNetPrice,
          vatPer: this.pkVatPer,
          vatPrice: this.totalpkVatPrice,
          grandTotalPrice: this.totalpkgrandTotalPrice,
          note: this.note,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getDataShowPart();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    alertDelete(rowsPart, index) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบหรือหรือไม่ !!?",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.deletePart(rowsPart, index);
          }
        });
    },
    alertConfirm() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการยืนยันการจ่ายอะไหล่หรือไม่ !!?",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.confirmSubmitPicking();
          }
        });
    },
    deletePart(rowsPart, index) {
      // this.pkId = rowsPart;
      // this.rowParts.splice(rowsPart, 1);
      this.rowsPart.splice(index, 1);
      this.calParts(this.rowsPart);
      this.overlayFlag = true;
      useNetw
        .delete("api/part-picking/part/delete", {
          data: {
            pickingId: this.pickingId,
            partId: rowsPart.partId,
            pickingTotalPrice: this.pkTotalPrice,
            pickingDcPer: this.pkDcPer,
            pickingDcPrice: this.totalPkDcPrice,
            pickingNetPrice: this.pkNetPrice,
            pickingVatPer: this.pkVatPer,
            pickingVatPrice: this.totalpkVatPrice,
            pickingGrandTotalPrice: this.totalpkgrandTotalPrice,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getDataShowPart();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    confirmSubmitPicking: function() {
      useNetw
        .put("api/part-picking/submit", {
          pickingId: this.pickingId,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
            name: "part-picking",
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message + '<br>' + err.response.data.errors),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
        });
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getDataRequisitions();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getDataRequisitions();
    },
    // onRowSelected(rows) {
    //   this.selected = rows;
    // },
    handleSearch() {
        this.getDataRequisitions();
    },
    customLabel({ partCode, nameTh }) {
      return `[${partCode}] - ${nameTh != null ? nameTh : ""}`;
    },

    getDataStock: function() {
      useNetw
        .get("api/master/stock-part", {
          params: {
            branchId: this.userBranchId,
          },
        })
        .then((response) => {
          this.rowsStock = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    getDataRequisitions: function(select) {
      useNetw
        .get("api/part-picking/requisitions", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            // branchId: this.userBranchId,
            branchId: this.userBranchId,
            roCode: this.filter.roCode,
            customerNameTh: this.filter.customerNameTh,
            customerFamilyNameTh: this.filter.customerFamilyNameTh,
            licensePlate: this.filter.licensePlate,
            reqDate: this.filter.reqDate,
          },
        })
        .then((response) => {
          this.rowsRequis = response.data.data;
          // this.from = response.data.data;
          // this.perPage = response.data.data;
          this.totalRecord = response.data.total;

          if (select) {
            this.rowsRequis.forEach((item) => {
              this.dcPer = item.dcPer;
              this.vatPer = item.vatPer;
              this.licensePlate = {vehicleId: item.vehicleId, licensePlate: item.licensePlate};
              this.customerNameTh = {
                nameTh: item.customerNameTh,
                familyNameTh: item.customerFamilyNameTh,
              };
              this.reqCode = {
                reqCode: item.reqCode,
                reqId: item.reqId,
              };
              this.pickingDate = {
                reqDate: item.reqDate,
              };
              // this.customerFamilyNameTh = item.customerFamilyNameTh;
              this.reqId = item.reqId;
            });
          }
          // Swal.fire(
          //     appConfig.swal.title.error,
          //     JSON.stringify(appConfig.swal.title.Unauthorization),
          //     appConfig.swal.type.error
          //   );
          //   this.rows.to = response.data.to;
          //   this.rows.from = response.data.from;
          //   this.rows.total = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    getDataCustomer: function() {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/customer", {
          params: {
            page: 1,
            perPage: 100,
            branchId: this.userBranch,
          },
        })
        .then((response) => {
          this.rowsUser = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getDataVehicle: function() {
      this.isLoading = true;
      useNetw
        .get("api/part-picking/vehicle", {
          params: {
            page: this.currentPage,
            perPage: 100,
            // vin: this.vin.vin,
            "branchId[]": this.userBranchId,
          },
        })
        .then((response) => {
          this.rowsVehicle = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    submitPicking: function() {
      const bodyFormData = new FormData();
      if (this.reqCode.reqId != null) {
        bodyFormData.append("reqId", this.reqCode.reqId);
      }
      bodyFormData.append("branchId", this.userBranchId);
      if (this.customerNameTh.cusId != null) {
        bodyFormData.append("cusId", this.customerNameTh.cusId);
      }
      if (this.licensePlate.vehicleId != null) {
        bodyFormData.append("vehicleId", this.licensePlate.vehicleId);
      }

      if (this.stockId.stockId != null) {
        bodyFormData.append("stockId", this.stockId.stockId);
      }

      bodyFormData.append("pickingDate", this.pickingDate.reqDate);
      bodyFormData.append("dcPer", this.dcPer);
      bodyFormData.append("vatPer", this.vatPer);
      if (this.note != "") {
        bodyFormData.append("note", this.note);
      }
      useNetw
        .post("api/part-picking/store", bodyFormData)
        .then((response) => {
          this.pickingId = response.data.pickingId;
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
            name: "detail-part-pickingId",
            params: { pickingId: this.pickingId },
          });
          this.isShow = true;
          this.btnShow = false;
          this.btnSaveShow = true;
          this.getData();
          this.getDataShowPart();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
        });
    },
    onRowSelected(rows) {
      this.reqCode = {reqId: rows[0].reqId, reqCode: rows[0].reqCode};
      this.licensePlate = {licensePlate: rows[0].licensePlate, vehicleId: rows[0].vehicleId};
      this.customerNameTh = {cusId: rows[0].cusId, nameTh: rows[0].customerNameTh, familyNameTh: rows[0].customerFamilyNameTh};
      this.pickingDate.reqDate = rows[0].reqDate;
      this.dcPer = rows[0].dcPer;
      // this.reqCode = rows[0].reqCode;
      // this.selected = rows;
      // this.getDataRequisitions(rows);
      this.$refs["modalSearchPicking"].hide();
    },
    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.$invalid != true) {
        this.submitPicking();
      }
    },
    customLabelName({ nameTh, familyNameTh }) {
      return `${nameTh}  ${familyNameTh}`;
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="card-title">Service List</h4> -->

            <!-- Search -->
            <div class="row justify-content-md-center">
              <div class="col-sm-12 col-md-12">
                <div class="row align-items-center">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 mt-3">
                      <div class="row">
                        <div class="col-10 col-sm-6 col-md-3">
                          <div class="mb-3 position-relative">
                            <label>reqCode:</label>
                            <multiselect
                              :options="rowsRequis"
                              v-model="reqCode"
                              :close-on-select="true"
                              :internal-search="true"
                              open-direction="bottom"
                              :show-labels="false"
                              label="reqCode"
                              placeholder=""
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                          </div>
                        </div>

                        <div class="col-2 col-sm-6 col-md-1">
                          <div class="mt-4 position-relative">
                            <b-button
                              class="btn"
                              variant="info"
                              v-b-modal.modalSearchPicking
                              @click="getDataRequisitions()"
                            >
                              <i class="uil-file-search-alt"></i
                            ></b-button>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                          <div class="mb-3 position-relative">
                            <label>ป้ายทะเบียน:</label>
                            <multiselect
                              :options="rowsVehicle"
                              v-model="licensePlate"
                              :close-on-select="true"
                              :internal-search="true"
                              open-direction="bottom"
                              :show-labels="false"
                              label="licensePlate"
                              placeholder=""
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                            <!-- <input
                              v-model="customerNameTh"
                              type="search"
                              class="form-control"
                            /> -->
                          </div>
                          <!-- <div class="mb-3 position-relative">
                            <label>ป้ายทะเบียน:</label>
                            <input
                              v-model="licensePlate"
                              type="search"
                              class="form-control"
                              disabled
                            />
                          </div> -->
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                          <div class="mb-3 position-relative">
                            <label>ชื่อ:</label>
                            <multiselect
                              :options="rowsUser"
                              v-model="customerNameTh"
                              :close-on-select="true"
                              :internal-search="true"
                              :custom-label="customLabelName"
                              open-direction="bottom"
                              :show-labels="false"
                              label="nameTh"
                              placeholder=""
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                            <!-- <input
                              v-model="customerNameTh"
                              type="search"
                              class="form-control"
                            /> -->
                          </div>
                        </div>
                        <!-- <div class="col col-sm-6 col-md-2">
                          <div class="mb-3 position-relative">
                            <label>สกุล:</label>
                            <input
                              v-model="customerFamilyNameTh"
                              type="search"
                              class="form-control"
                            />
                          </div>
                        </div> -->
                        <div class="col-12 col-sm-6 col-md-3">
                          <div class="mb-3 position-relative">
                            <code> * </code><label>วันที่จ่ายอะไหล่:</label>
                            <date-picker
                              v-model="pickingDate.reqDate"
                              format="YYYY-MM-DD"
                              value-type="format"
                              :class="{
                                'is-invalid':
                                  submitform && $v.pickingDate.reqDate.$error,
                              }"
                            ></date-picker>
                            <div
                              v-if="submitform && $v.pickingDate.reqDate.$error"
                              class="invalid-tooltip"
                            >
                              <span v-if="!$v.pickingDate.reqDate.required">{{
                                errormessage
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                          <div class="mb-3 position-relative">
                            <label>คลังสินค้า:</label>
                            <multiselect
                              :options="rowsStock"
                              v-model="stockId"
                              :close-on-select="true"
                              :internal-search="true"
                              open-direction="bottom"
                              :show-labels="false"
                              label="nameTh"
                              placeholder=""
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-2">
                          <div class="mb-3 position-relative">
                            <code> * </code><label>ส่วนลด %:</label>
                            <input
                              v-model="dcPer"
                              type="search"
                              class="form-control"
                              :class="{
                                'is-invalid': submitform && $v.dcPer.$error,
                              }"
                            />
                            <div
                              v-if="submitform && $v.dcPer.$error"
                              class="invalid-tooltip"
                            >
                              <span v-if="!$v.dcPer.required">{{
                                errormessage
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-2">
                          <div class="mb-3 position-relative">
                            <code> * </code><label>Vat (%):</label>
                            <input
                              v-model="vatPer"
                              type="search"
                              class="form-control"
                              disabled
                              :class="{
                                'is-invalid': submitform && $v.vatPer.$error,
                              }"
                            />
                            <div
                              v-if="submitform && $v.vatPer.$error"
                              class="invalid-tooltip"
                            >
                              <span v-if="!$v.vatPer.required">{{
                                errormessage
                              }}</span>
                            </div>
                          </div>
                        </div>

                        <div class="col-12 col-sm-6 col-md-4">
                          <div class="mb-3 position-relative">
                            <label>หมายเหตุ:</label>
                            <b-form-textarea v-model="note"></b-form-textarea>
                          </div>
                        </div>

                        <div class="col-6 col-sm-5 col-md-12" v-if="btnShow">
                          <div class="mb-3 position-relative">
                            <b-button
                              class="btn mt-4 float-end"
                              variant="success"
                              type="submit"
                              @click="tooltipForm"
                            >
                              <!-- <i class="uil-search-alt"></i> -->
                              บันทึก
                            </b-button>
                          </div>
                        </div>
                        <div
                          class="col-12 col-sm-5 col-md-12"
                          v-if="btnSaveShow"
                        >
                          <div class="mb-3 position-relative">
                            <button
                              class="btn btn-info ms-1 float-end"
                              @click="updatePkPartMaster()"
                              :disabled="pkStatus === 'APV'"
                            >
                              บันทึกการแก้ไขข้อมูล
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
          </div>
        </div>
      </div>
    </div>

    <b-modal
      ref="modalSearchPicking"
      id="modalSearchPicking"
      title="รายการสั่งซ่อม"
      hide-footer
      size="xl"
      centered
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12 align-items-center">
            <div class="row align-items-center">
              <!-- <div class="col-6 col-sm-4 col-md-3">
                <label class="d-inline align-items-center m-2 text-end">
                  เลขที่ใบแจ้งซ่อม
                  <b-form-input
                    v-model="filter.roCode"
                    type="search"
                    placeholder="เลขที่ใบแจ้งซ่อม"
                    class="form-control ms-2"
                    @keyup.enter="handleSearch"
                  ></b-form-input>
                </label>
              </div> -->

              <div class="col-6 col-sm-4 col-md-3">
                <label class="d-inline align-items-center m-2">
                  ชื่อ
                  <b-form-input
                    v-model="filter.customerNameTh"
                    type="search"
                    placeholder="ชื่อ"
                    class="form-control ms-2"
                    @keyup.enter="handleSearch"
                  ></b-form-input>
                </label>
              </div>

              <div class="col-6 col-sm-4 col-md-3">
                <label class="d-inline align-items-center m-2">
                  นามสกุล
                  <b-form-input
                    v-model="filter.customerFamilyNameTh"
                    type="search"
                    placeholder="นามสกุล"
                    class="form-control ms-2"
                    @keyup.enter="handleSearch"
                  ></b-form-input>
                </label>
              </div>

              <div class="col-6 col-sm-4 col-md-3">
                <label class="d-inline align-items-center m-2">
                  ทะเบียนรถ
                  <b-form-input
                    v-model="filter.licensePlate"
                    type="search"
                    placeholder="ทะเบียนรถ"
                    class="form-control ms-2"
                    @keyup.enter="handleSearch"
                  ></b-form-input>
                </label>
              </div>

              <div class="col-6 col-md-3 col-sm-2">
                <div class="mb-4 position-relative">
                  <label class="d-inline align-items-center m-2">
                    วันที่สั่งซ่อม</label
                  >

                  <date-picker
                    v-model="filter.reqDate"
                    :first-day-of-week="1"
                    format="YYYY-MM-DD"
                    @input="handleSearch"
                    value-type="format"
                    lang="en"
                  ></date-picker>
                </div>
              </div>

              <div
                class="col-2 col-sm-2 col-md-2 text-end"
                style="margin-left: auto; margin-right: 0"
              >
                <b-button
                  class="btn btn ms-2"
                  variant="info"
                  type="submit"
                  @click="handleSearch"
                >
                  <i class="uil-search"></i>
                  ค้นหา
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-table
              :rows="5"
              :columns="6"
              :table-props="{ bordered: false, striped: true }"
              animation="throb"
            ></b-skeleton-table>
          </template>
          <div class="row mt-2">
            <div class="col-sm-12 col-md-12">
              <div class="row align-items-center">
                <div class="col-sm-5 col-md-3">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-block align-items-center">
                      แสดงผล
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        @input="handlePageChange"
                      ></b-form-select
                      >&nbsp; รายการ
                    </label>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6"></div>
              </div>
            </div>
          </div>

          <div class="table-responsive mb-0">
            <b-table
              :items="rowsRequis"
              :fields="fields"
              responsive="true"
              :per-page="perPage"
              :current-page="1"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              ref="selectableTable"
              selectable
              :select-mode="selectMode"
              @row-selected="onRowSelected"
            >
            <template #cell(index)="rowsRequis">
                    {{ rowsRequis.index + 1 }}
                  </template>
            </b-table>
          </div>

          <div class="row">
            <!-- <div class="col">
              หน้า {{ currentPage }} จาก {{ this.totalPage }} ทั้งหมด
              {{ totalRecord }} รายการ
            </div> -->

            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRecord"
                    :per-page="perPage"
                    @change="handleChangePage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
            <br />
            <hr />
            <br />
          </div>
        </b-skeleton-wrapper>
      </div>

      <div class="row">
        <div class="col-md">
          <button class="btn btn-success float-end">ตกลง</button>
        </div>
      </div>
    </b-modal>

    <div class="row" v-if="isShow">
      <div class="col-12">
        <b-skeleton-wrapper :loading="loading">
          <!-- skeleton  -->
          <template #loading>
            <b-skeleton-table></b-skeleton-table>
          </template>
          <div class="card">
            <div class="card-body">
              <!-- <h4 class="card-title">Service List</h4> -->
              <div class="row">
                <div class="col-6">
                  <p class="card-title-desc">รายการจ่ายอะไหล่</p>
                </div>
                <div class="col-6">
                  <p class="badge bg-success font-size-16 float-end" v-if="pkStatus === 'APV'">จ่ายอะไหล่แล้ว</p>
                  <p class="badge bg-warning font-size-14 float-end" v-if="pkStatus === 'DFT'">ร่าง</p>
                  <p class="badge bg-danger font-size-14 float-end" v-if="pkStatus === 'CAN'">ยกเลิกแล้ว</p>
                </div>
                <div class="col-12">
                  <code>** หมายเหตุ : กรณีแก้ไขข้อมูลอะไหล่ เช่น ราคา,จำนวน,ส่วนลด หลังแก้ไขเสร็จให้กดปุ่ม Enter เพื่อบันทึกข้อมูล</code>
                </div>
              </div>
              <!-- Search -->
              <div class="row justify-content-md-center">
                <div class="col-sm-12 col-md-12">
                  <div class="row align-items-center">
                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-12">
                        <!-- Table -->
                        <div class="table-responsive">
                          <table class="table">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">รหัสอะไหล่</th>
                                <th scope="col">ชื่ออะไหล่</th>
                                <th scope="col">ราคา/หน่วย</th>
                                <th scope="col">จำนวน</th>
                                <th scope="col" class="text-end">รวม</th>
                                <th scope="col">ส่วนลด</th>
                                <th scope="col">ส่วนลดยอด</th>
                                <th scope="col" class="text-end">
                                  จำนวนเงิน
                                </th>
                                <!-- <th scope="col" class="text-end">vat (%)</th>
                              <th scope="col" class="text-end">vat</th>
                              <th scope="col" class="text-end">สุทธิ</th> -->
                                <th scope="col" v-if="pkStatus === 'DFT'">
                                  ลบ
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(partItem, index) in rowsPart"
                                :key="index"
                              >
                                <th scope="row">{{ index + 1 }}</th>
                                <td>{{ partItem.partCode }}</td>
                                <td>{{ partItem.partNameTh }}</td>
                                <td class="number">
                                  <input
                                    v-model="partItem.pricePerOne"
                                    @input="calParts(partItem)"
                                    @keypress.enter="updatePkPart(partItem)"
                                    class="form-control text-end"
                                    :disabled="pkStatus === 'APV'"
                                    type="number"
                                  />
                                </td>

                                <td class="Dc">
                                  <input
                                    v-model="partItem.amount"
                                    @input="calParts(partItem)"
                                    @keypress.enter="updatePkPart(partItem)"
                                    class="form-control text-end"
                                    :disabled="pkStatus === 'APV'"
                                    type="number"
                                  />
                                </td>
                                <td class="text-end">
                                  {{
                                    Number(
                                      parseFloat(partItem.totalPrice).toFixed(2)
                                    ).toLocaleString()
                                  }}
                                </td>
                                <td class="Dc">
                                  <input
                                    v-model="partItem.dcPer"
                                    @input="calParts(partItem)"
                                    @keypress.enter="updatePkPart(partItem)"
                                    class="form-control text-end"
                                    :disabled="pkStatus === 'APV'"
                                    type="number"
                                  />
                                </td>
                                <td class="text-end">
                                  {{
                                    Number(
                                      parseFloat(partItem.dcPrice).toFixed(2)
                                    ).toLocaleString()
                                  }}
                                </td>
                                <td class="text-end">
                                  {{
                                    Number(
                                      parseFloat(partItem.netPrice).toFixed(2)
                                    ).toLocaleString()
                                  }}
                                </td>
                                <!-- <td class="text-end">
                                {{ partItem.vatPer.toFixed(2) }}
                              </td>
                              <td class="text-end">
                                {{ partItem.vatPrice.toFixed(2) }}
                              </td>
                              <td class="text-end">
                                {{
                                  Number(
                                    parseFloat(
                                      partItem.grandTotalPrice
                                    ).toFixed(2)
                                  ).toLocaleString()
                                }}
                              </td> -->
                                <td v-if="pkStatus === 'DFT'">
                                  <ul class="list-inline mb-0 action">
                                    <li class="list-inline-item">
                                      <a
                                        class="px-2 text-danger"
                                        v-b-tooltip.hover
                                        title="Delete"
                                        @click="alertDelete(partItem, index)"
                                      >
                                        <i
                                          class="uil uil-trash-alt font-size-18"
                                        ></i>
                                      </a>
                                    </li>
                                  </ul>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="10">
                                  <p class="card-title-desc">ค้นหารายการอะไหล่</p>
                          <div class="col-md-6">
                            <div class="mb-3 position-relative">
                              <label
                                ><code>*</code>
                                ค้นหารหัสอะไหล่,ชื่ออะไหล่</label
                              >
                              <multiselect
                                v-model="partsCode"
                                :options="rowParts"
                                :class="{
                                  'is-invalid': submitform,
                                }"
                                label="partId"
                                :custom-label="customLabel"
                                :show-labels="false"
                                track-by="nameTh"
                                @input="showModal(partsCode)"
                                @search-change="getParts($event)"
                                :loading="loading"
                                :close-on-select="true"
                                :internal-search="true"
                                open-direction="bottom"
                                :disabled="pkStatus === 'APV'"
                              >
                              </multiselect>

                              <!-- @ok="handleOk" -->
                            </div>
                          </div>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row" colspan="9" class="text-end">
                                  รวมค่าอะไหล่ :
                                </th>
                                <td class="text-end">
                                  {{
                                    Number(
                                      parseFloat(this.totalnetprice).toFixed(2)
                                    ).toLocaleString()
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  scope="row"
                                  colspan="9"
                                  class="border-0 text-end"
                                >
                                  ส่วนลดรวม:
                                </th>
                                <td class="border-0 text-end">
                                  {{ this.pkDcPer }}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  scope="row"
                                  colspan="9"
                                  class="border-0 text-end"
                                >
                                  ส่วนลดยอดรวม:
                                </th>
                                <td class="border-0 text-end">
                                  {{
                                    parseFloat(this.totalPkDcPrice).toFixed(2)
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  scope="row"
                                  colspan="9"
                                  class="border-0 text-end"
                                >
                                  จำนวนเงินรวม:
                                </th>
                                <td class="border-0 text-end">
                                  {{
                                    Number(
                                      parseFloat(this.pkNetPrice).toFixed(2)
                                    ).toLocaleString()
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  scope="row"
                                  colspan="9"
                                  class="border-0 text-end"
                                >
                                  ภาษีรวม(%):
                                </th>
                                <td class="border-0 text-end">
                                  {{ this.pkVatPer }}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  scope="row"
                                  colspan="9"
                                  class="border-0 text-end"
                                >
                                  ภาษีรวม(บาท):
                                </th>
                                <td class="border-0 text-end">
                                  {{
                                    parseFloat(this.totalpkVatPrice)
                                      .toFixed(2)
                                      .toLocaleString()
                                  }}
                                </td>
                              </tr>
                              <!-- <tr>
                              <th
                                scope="row"
                                colspan="11"
                                class="border-0 text-end"
                              >
                                ยอดเงินรวม:
                              </th>
                              <td class="border-0 text-end">
                                {{
                                  Number(
                                    parseFloat(
                                      this.totalpkgrandTotalPrice
                                    ).toFixed(2)
                                  ).toLocaleString()
                                }}
                              </td>
                            </tr> -->
                            </tbody>
                          </table>
                        </div>
                        <div class="row">
                          <div class="col-12 d-flex justify-content-end">
                            <div
                              class="col-12 col-sm-5 col-md-5 bg-dark text-white m-0 text-end"
                              style="border-radius:10px;
                            margin:auto;
                            justify "
                            >
                              <div class="row align-items-center m-0">
                                <div class="col-12">
                                  <div class="row mt-2">
                                    <div class="col-4 col-sm-5 col-md-3">
                                      มูลค่ารวม :
                                    </div>
                                    <div class="col-6 font-size-24">
                                      {{
                                        Number(
                                          this.totalpkgrandTotalPrice
                                        ).toLocaleString()
                                      }}
                                    </div>
                                    <div class="col-2">
                                      บาท
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <hr />
                        <!-- search-->
                        <div class="row">
                          <div class="col-md-12">
                            <div class="mb-3 position-relative">
                              <b-button
                                variant="danger"
                                class="float-end m-4"
                                @click="alertConfirm"
                                :disabled="pkStatus === 'APV'"
                              >
                                ยืนยันการจ่ายอะไหล่
                              </b-button>
                            </div>
                          </div>
                        </div>
                        <b-modal
                          ref="modalAmout"
                          title="ระบุจำนวน"
                          hide-footer
                          size="sm"
                          centered
                          :state="nameState"
                        >
                          <div class="row">
                            <div class="col">
                              <div class="mb-3 position-relative">
                                <label><code>*</code> จำนวน</label>
                                <!-- <span>({{ this.part.nameTh }})</span> -->
                                <input
                                  :state="nameState"
                                  v-model="part.amount"
                                  type="number"
                                  class="form-control text-center"
                                  value="Otto"
                                />
                              </div>
                            </div>
                          </div>

                          <!-- :class="{ 'is-invalid': submitform && $v.amount.$error,
                        }"
                        <div
                          v-if="submitform && $v.amount.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.amount.required">{{ message }}</span>
                        </div> -->

                          <div class="row">
                            <div class="col-lg-12">
                              <div class="text-end">
                                <button
                                  class="btn btn-primary"
                                  @click="postPkPart(part)"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </b-modal>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <!-- Table -->
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
    </div>
  </Layout>
</template>
